<template>
  <el-card class="iot-person iot-search-header public-page-style">
    <div slot="header" class="clearfix">
     <span style="display:inline-block;margin-right:50px;color: #9e9399;">剩余语音：<el-tag >{{smsData.phoneNum}}次</el-tag></span>
     <span style="display:inline-block;margin-right:50px;color: #9e9399;">剩余短信：<el-tag >{{smsData.smsNum}}次</el-tag></span>
     <el-button type="primary" size="mini" @click="nativepay" plain  v-if="$store.state.permissionArr.indexOf('smsUser:view') > -1">充值</el-button>
     <el-button type="primary" size="mini" @click="recording"  plain  v-if="$store.state.permissionArr.indexOf('smsUser:view') > -1">充值记录</el-button>
     <el-tooltip class="item" effect="light"  placement="right-end">
      <div slot="content">语音通知：同一个被叫，1次/分钟、5次/小时、20次/24小时。
        <br/>短信通知：对同一个手机号码发送短信通知，最多支持50条/天。</div>
      <i class="el-icon-question"></i>
    </el-tooltip>
      <el-form :inline="true" :model="getForm">
        <el-button
          type="primary"
          size="mini"
          plain
          class="iot-contacts-create"
          v-if="$store.state.permissionArr.indexOf('contacts:add') > -1"
          @click="addContacts()"
          >新增</el-button
        >
      </el-form>
    </div>
  <div  class="tab" style="height:690px;overflow-y: scroll;">
    <el-table
      :data="tableData"
      stripe
      style="width: 100%"
      :header-cell-style="{ background: theme.TABLE_HEADER_COLOR }"
    >
      <el-table-column prop="contactsId" label="序号"></el-table-column>
      <el-table-column prop="contactsName" label="姓名"></el-table-column>
      <el-table-column prop="mobile" label="电话"></el-table-column>
      <el-table-column label="处理方式">
        <template slot-scope="scope">
          <div>
            <span
              v-for="(item, index) in scope.row.processingMethods"
              :key="index"
              >{{
                item.processingMethod +
                (scope.row.processingMethods.length > index + 1 ? "，" : "")
              }}</span
            >
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            plain
            @click="editContacts(scope.row)"
            >修改</el-button
          >
          <el-button
            size="mini"
            type="danger"
            plain
            @click="delContacts(scope.row.contactsId)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    </div>
    <div   style="display:flex;justify-content: flex-end;">
      <el-pagination
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="getForm.current"
      :page-sizes="[10, 20, 30, 40]"
        :total="total"
        :page-size="getForm.size"
        layout="total, sizes, prev, pager, next, jumper"
        @prev-click="
          () => {
            $set(getForm, 'current', getForm.current - 1);
            getList();
          }
        "
        @next-click="
          () => {
            $set(getForm, 'current', getForm.current + 1);
            getList();
          }
        "
      >
      </el-pagination></div>
    <el-dialog
      title="填写表单"
      :show-close="false"
      :visible.sync="dialogVisinbile"
      @close="handleClose"
      :destroy-on-close="true"
      :close-on-press-escape="true"
      :close-on-click-modal="false"
      class="iot-contacts"
      width="30%"
    >
      <el-form
        :model="contactsForm"
        ref="subRef"
        label-width="120px"
        :rules="rules"
      >
        <el-form-item label="联系人姓名" prop="contactsName">
          <el-input
            v-model="contactsForm.contactsName"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="mobile">
          <el-input v-model="contactsForm.mobile" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="处理方式" prop="processingMethods">
          <el-checkbox-group v-model="contactsForm.processingMethods">
            <el-checkbox
              v-for="item in processingMethodArr"
              :key="item.processingMethodId"
              :label="item.processingMethodId"
              >{{ item.processingMethod }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="handleClose">取 消</el-button>
      </div>
    </el-dialog>
    <!-- 充值弹层 -->
    <el-dialog
  title="套餐列表"
  :visible.sync="dialogVisible"
  width="30%"
  >
 <el-row class="payList" >
  <el-card class="payCard" v-for="item in payList" :key="item.packId" :class="{selected:radio1.packName===item.packName}">
  <el-radio :label="item" v-model="radio1">{{item.packName}}</el-radio>
  <el-tag type="success">￥{{item.moneyNum}}元</el-tag>
  <el-tag type="success" style="margin:5px 5px">{{item.smsNum}}次</el-tag>
  </el-card>
 </el-row>
  <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible=false,radio1=''">取 消</el-button>
    <el-button type="primary" @click.stop="initiateAPayment" :disabled="!radio1">提交订单</el-button>
  </span>
    
</el-dialog>
<!-- 充值列表弹层 -->
<el-dialog title="充值记录" :visible.sync="dialogTableVisible">
  <el-table :data="gridData" stripe>
    <el-table-column prop="description" label="订单名称" width="100" align="center"></el-table-column>
    <el-table-column prop="outTradeNo" label="订单号" width="160" align="center"></el-table-column>
    <el-table-column prop="moneyNum" label="订单金额" width="80" align="center">
      <template slot-scope="scope"> ￥{{scope.row.moneyNum}}</template>
    </el-table-column>
    <el-table-column prop="moneyNum" label="订单数量" width="80" align="center">
      <template slot-scope="scope">
        <span v-if="scope.row.smsNum">{{scope.row.smsNum}}次</span>
      </template>
    </el-table-column>
    <el-table-column prop="createTime" label="创建时间" align="center"></el-table-column>
    <el-table-column prop="address" label="支付状态" align="center">
      <template slot-scope="scope">
        <el-tag type="success" v-if="scope.row.payState==1">已支付</el-tag>
        <el-tag type="warning" v-else-if="scope.row.payState===0 && getPayTime(scope.row.createTime)">未支付</el-tag>
        <el-tag type="info" v-else>已过期</el-tag>
      </template>
    </el-table-column>
    <el-table-column prop="address" label="操作">
      <template slot-scope="scope">
        <el-button  size="mini"  type="danger" plain  @click="deletRecords(scope.row.paySmsId)">删除</el-button>
        <el-button v-if="scope.row.payState==0 && getPayTime(scope.row.createTime)"  size="mini"  type="primary" plain  @click="repayment(scope)">支付</el-button>
      </template>
    </el-table-column>
  </el-table>
  <span slot="footer" class="dialog-footer">
    <el-pagination
      @size-change="recordsSizeChange"
      @current-change="recordsCurrentChange"
      :page-sizes="[2,5,10]"
      :pager-count=5
      :page-size="getRecordsList.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="getRecordsList.total">
    </el-pagination>
  </span>
</el-dialog>
 <!-- 流量充值弹出层S -->
 <el-dialog
     append-to-body
      title="套餐充值"
      :visible.sync="payIccidVis"
      v-if="payIccidVis"
      width="22%"
      style="text-align: left; color: #409eff"
    >
      <el-row v-if="isShow">
        <div class="payBox">
        <div style="margin: 15px 0; color: #409eff; font-size: 20px">
          {{iccidInfo.description}}
        </div>
        <div id="qrCode" style="width: 200px; height: 200px"></div>
        <div style="margin: 15px 0; color: red; font-size: 18px">
          ￥{{ iccidInfo.moneyNum }}元
        </div>
      </div>
      </el-row>
      <el-row v-else style="width:378px;height:280px"> 
        <el-result icon="success" title="充值成功" class="result"></el-result>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" type="primary" @click.stop="payIccidVis=false"
          >关 闭</el-button
        >
      </div>
    </el-dialog>
  </el-card>
</template>
<script>
import QRCode from "qrcodejs2";
import { TABLE_HEADER_COLOR } from "../util/theme";
import reg from "../util/regular";
let that;
export default {
  name: "Contacts",
  data() {
    return {
      phoneState: 0,
      smsState:0,
      gridData: [],
      getRecordsList: {
        current: 1,
        size: 5,
        total:0
      },
      dialogTableVisible:false,
      isShow:true,
      timer:null,
      iccidInfo:{},
      payIccidVis:false,
      radio1:'',
      payList:[],
      smsData: {},
      dialogVisible:false,
      theme: { TABLE_HEADER_COLOR },
      dialogVisinbile: false,
      tableData: [],
      processingMethodArr: [],
      getForm: {
        contactsName: "",
        size: 10,
        current: 1,
      },
      total: 0,
      contactsForm: {
        contactsName: "",
        contactsId: "",
        processingMethods: [],
      },
      permissionForm: {},
      isCreate: true,
      defaultProps: {
        label: "menuName",
        children: "children",
      },
      rules: {
        contactsName: [
          { required: true, message: "请输入联系人名称", trigger: "blur" },
        ],
        mobile: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: reg.mobileR,
            message: "手机号格式有误",
          },
        ],
      },
    };
  },
  methods: {
    createQrCode(id,url) {
      //创建二维码
      new QRCode(id, {
        width: 180,
        height: 180,
        colorDark: "#000000", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        correctLevel: QRCode.CorrectLevel.H, //容错率，L/M/H
        text:url,
      });
    },
      // 开通语音短信状态
      updateState() {
      let data = {
        userId: that.$store.state.userId,
        phoneState: this.phoneState,
        smsState:this.smsState
      }
      this.$api.contacts.updateState(data).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.msg)
        }
      })
    },
      //得到支付时间
      getPayTime(time) {
      let times = +new Date(time);
      let newTime = +new Date();
      if (newTime < times + 7200000) {
        return true;
      } else {
        return false;
      }
    },
    // 重新发起支付
    async repayment({ row }) {
      console.log(row);
      this.iccidInfo = row
      this.payIccidVis = true
      this.$nextTick(() => {
        this.createQrCode('qrCode',row.codeUrl)
      })
       that.getPayStatus(that.iccidInfo.paySmsId)
    },
  async  deletRecords(data) {
      try {
        await    this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        await this.$api.contacts.deleteRecords(data)
        this.$message.success('删除成功')
        this.recording()
      } catch (e) {
      this.$message.info(e.msg||'取消删除')
    }
    },
    recordsSizeChange(val) {
      this.getRecordsList.size = val
      this.recording()
    },
    recordsCurrentChange(val) {
      this.getRecordsList.current = val
      this.recording()
    },
     // 充值记录
     recording() {
      this.dialogTableVisible = true
      this.$api.contacts.getRecords(this.getRecordsList).then((res) => {
        this.$set(this, 'gridData', res.data.records)
        this.getRecordsList.total = Number(res.data.total)
        if(res.data.records.length === 0 && res.data.total > 0) {
			this.getRecordsList.current = res.data.pages
			this.recording()
		}
      })
    },
    // 发起支付
    async initiateAPayment() {
    this.isShow=true
      let data = {
        topType: 2,
        description: this.radio1.packName,
        total: this.radio1.moneyNum,
        packId: this.radio1.packId,
        smsNum:this.radio1.smsNum
      }
   await   this.$api.contacts.NativePay(data).then((res) => {
     if (res.code == 200) {
          console.log(this.iccidInfo);
          this.iccidInfo = res.data
          this.payIccidVis = true
          this.$nextTick(() => {
            this.createQrCode('qrCode', this.iccidInfo.codeUrl)
          })
          that.getPayStatus(that.iccidInfo.paySmsId)
        }
      })
    },
    // 获取支付状态
    getPayStatus(data) {
      that.timer = setInterval(async () => {
         await   this.$api.contacts.payStatus(data).then((res) => {
              if (res.data.payState == 1) {
             that.isShow=false
             this.$message.success('充值成功')
             this.radio1 = ''
             window.clearInterval(that.timer)
             that.timer = null
            that.getSmsNum()
                setTimeout(() => {
                  that.payIccidVis = false
                  if (that.iccidInfo.createTime) {
                      that.recording()
                }
             },2000)
              }
            })
            if (!this.payIccidVis) {
            window.clearInterval(that.timer)
            that.timer = null
             return
            } 
         }, 2000)
     
    },
    // 获取支付列表
    nativepay() {
      this.$api.contacts.getSmsPack().then((res) => {
        if (res.code == 200) {
          this.payList = res.data
          this.dialogVisible = true
        }
      })
    },
    // 获取短信语音数量
    getSmsNum() {
      this.$api.contacts.getSmsNum().then((res) => {
        if (res.code == 200) {
        this.smsData=res.data
       }
      })
    },
    //分页方法
     handleSizeChange(val) {
        that.getForm.size=val;
        that.getList();
      },
      handleCurrentChange(val) {
        that.getForm.current=val;
        that.getList();
      },
    dealProcessingMethod: function (data) {
      let newData = [];
      data.forEach((element) => {
        newData.push({
          processingMethodId: element,
        });
      });

      return newData;
    },
    translateProcessingMethod: function (data) {
      let d = Object.assign({}, data);
      let newData = [];
      d.processingMethods.forEach((element) => {
        newData.push(element.processingMethodId);
      });
      d.processingMethods = newData;

      return d;
    },
    submitForm: function () {
      that.$refs["subRef"].validate((valid) => {
        if (valid) {
          let formData = Object.assign({}, that.contactsForm);
          let newD = that.dealProcessingMethod(formData.processingMethods);
          formData.processingMethods = newD;

          let _api = that.isCreate
            ? that.$api.contacts.addContacts(formData)
            : that.$api.contacts.editContacts(formData);

          _api.then((response) => {
            if (response.code == 200) {
              that.dialogVisinbile = false;
              that.isCreate = true;
              that.$refs["subRef"].resetFields();
              that.getList();
              that.$message({
                type: "success",
                message: response.msg,
              });
            }
          });
        }
      });
    },
    checkTable: function () {
      that.$set(that.getForm, "current", 1);
      that.getList();
    },
    processingMethod: function () {
      that.$api.contacts.processingMethod().then((response) => {
        that.processingMethodArr = response.data;
      });
    },
    getList: function () {
      that.$api.contacts.contactsList(that.getForm).then((response) => {
        that.tableData = response.data.records;
        that.total = response.data.total;
      });
    },
    addContacts: function () {
      that.dialogVisinbile = true;
      that.isCreate = true;
      that.processingMethod();
    },
    handleClose: function () {
      that.dialogVisinbile = false;
      that.contactsForm= {
        contactsName: "",
        contactsId: "",
        processingMethods: [],
      }
    },
    editContacts: function (obj) {
      let newD = that.translateProcessingMethod(obj);
      that.contactsForm = Object.assign({}, that.contactsForm, newD);
      that.dialogVisinbile = true;
      that.processingMethod();
      that.isCreate = false;
    },
    delContacts: function (contactsId) {
      that
        .$confirm("确认删除", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          that.$api.contacts.delContacts(contactsId).then((response) => {
            if (response.code == 200) {
              that.$message({
                type: "success",
                message: response.msg,
              });
              that.getList();
            }
          });
        })
        .catch(() => {});
    },
  },
  beforeCreate: function () {
    that = this;
  },
  mounted: function () {
    that.getList();
    that.getSmsNum()
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.el-select,
.el-input,
.el-input-number {
  width: 250px;
}
.el-input-number >>> input {
  text-align: left;
}
.iot-search-header >>> .el-card__header {
  position: relative;
  height: 50px;
}
.iot-contacts-create {
  position: absolute;
  right: 10px;
  top: 0;
}
.iot-contacts-permission {
  height: 387px;
}
.iot-contacts >>> .el-dialog {
  height: 400px;
}
::v-deep .tab::-webkit-scrollbar{
  display: none;
}
.QRCode{
  width: 180px;
  height: 180px;
}
.payList{
  display: flex;
  flex-wrap: wrap;
}
.payCard{
  width: 30%;
  margin: 5px ;
}
.payBox{
  text-align: center;
}
#qrCode{
  margin: 0 auto;
}
.result{
  position: absolute;
  left: 50%;
  top:50%;
  transform: translate(-50%,-50%);
}
.selected{
  background-color: aquamarine;
  box-shadow: 0 0 2px 1px #66b1ff;
}
.el-icon-question{
  font-size: 20px;
  color:#999;
  display: inline-block;
  margin-left: 10px;
}
</style>
